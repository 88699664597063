/* eslint-disable max-len */
import React from 'react';

const DiscordLogo = () => (
  <svg width="23" height="27" viewBox="0 0 23 27" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M22.9231 24.8486C22.9231 17.7425 22.9231 10.6822 22.9231 3.576C22.9231 1.10031 21.8686 0 19.4846 0C14.1206 0 8.80246 0 3.43846 0C1.10031 0 0 1.10031 0 3.43846C0 8.71077 0 13.9831 0 19.2554C0 21.9145 1.00862 22.9231 3.66769 22.9231C7.56462 22.9231 11.4615 22.9231 15.3585 22.9231C17.3757 22.9231 17.3757 22.9231 17.1923 20.9058C19.072 22.6938 20.86 24.3902 22.7397 26.1323C23.0148 25.5822 22.9231 25.2154 22.9231 24.8486ZM16.2295 16.9631C15.9545 17.0089 15.6794 17.0548 15.4502 17.0089C14.9917 17.0089 14.7625 16.6422 14.6249 16.2754C14.4415 15.8169 14.9458 15.8628 15.1292 15.7252C15.3126 15.5877 15.5877 15.5877 15.7252 15.0375C12.7911 16.2295 9.99446 16.1378 7.10615 15.0375C7.19785 15.5877 7.56461 15.5418 7.748 15.7252C7.97723 15.8628 8.43569 15.8628 8.25231 16.2754C8.11477 16.5963 7.88554 16.9172 7.42708 17.0089C5.54738 17.3298 3.576 15.496 3.75938 13.6163C3.94277 12.0117 4.30954 10.4529 4.85969 8.94C5.59323 7.06031 6.87692 6.18923 8.98585 6.18923C9.12338 6.60185 8.48154 6.41846 8.52738 6.87692C10.4529 6.64769 12.3785 6.37261 14.2123 7.06031C14.3498 6.51015 13.7997 6.60185 13.7538 6.28092C13.7997 6.23508 13.8455 6.18923 13.8914 6.18923C15.7711 6.37262 17.3298 7.01446 17.9258 9.07754C18.3385 10.4529 18.7969 11.8283 18.9345 13.2954C19.3012 15.496 18.4302 16.5963 16.2295 16.9631Z" />
      <path d="M13.8911 13.8457C13.02 13.754 12.6074 13.2497 12.6074 12.4244C12.6074 11.645 13.02 11.0949 13.7994 11.049C14.6705 11.0032 15.129 11.5534 15.129 12.4244C15.1748 13.2497 14.7622 13.754 13.8911 13.8457Z" />
      <path d="M10.3604 12.3786C10.3145 13.2955 9.90189 13.8457 9.03082 13.7998C8.25143 13.7998 7.79297 13.2497 7.79297 12.4703C7.79297 11.6451 8.20558 11.0491 9.07666 11.0491C9.94774 11.0491 10.3145 11.6451 10.3604 12.3786Z" />
    </g>
  </svg>
);

export default DiscordLogo;
